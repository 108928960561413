import { Navigate } from 'react-router-dom';
import React, {useContext, useEffect} from "react";
import {OwnerContext} from "../providers/OwnerProvider";

const AdminRoute = ({children }) => {
    const { myAccount, getMyRole} = useContext(OwnerContext);
    useEffect(async ()=>{
        if(myAccount){
            await getMyRole(myAccount)
        }
    },[myAccount]);
    if (myAccount && myAccount.role!=="ADMIN") {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default AdminRoute
